import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const WaterLevelStackedBarChart = ({ type, waterLevel, capacity }) => {
  const data = [
    {
      name: type,
      waterLevel,
      capacity: capacity - waterLevel,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorSmaller" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#F1B833" />
            <stop offset="100%" stopColor="#EEE0B0" />
          </linearGradient>
        </defs>

        <XAxis dataKey="name" hide />
        <YAxis reversed={true} hide />
        <Bar dataKey="capacity" stackId="a" fill="#FFFDFD" barSize={30}>
          <LabelList position="right" fontSize={8}>
            Capacity
          </LabelList>
        </Bar>
        <Bar
          dataKey="waterLevel"
          stackId="a"
          fill="url(#colorSmaller)"
          barSize={30}
        >
          <LabelList position="right" fontSize={8}>
            Water level
          </LabelList>
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WaterLevelStackedBarChart;
