import { GiPlainSquare } from 'react-icons/gi';
import styles from './ElevatedStorageReservoir.module.css';
import { FaArrowsAltV } from 'react-icons/fa';
import { FaArrowsAltH } from 'react-icons/fa';
import PipeImg from '../../../../../assets/images/pipe.png';

const PipeDistributionNetwork = ({ data }) => {
  return (
    <div>
      <div className={styles.header}>
        Piped Network Distribution to Households
      </div>
      <div className={styles.content}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            marginTop: '5%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', width: '70%', alignItems: 'center' }}
            >
              <div style={{ marginRight: '10px' }}>
                <GiPlainSquare color="#9CE5F4" fontSize={'22px'} />
              </div>
              <div style={{ fontSize: '12px' }}>
                Functional household tap connections
              </div>
            </div>
            <div style={{ width: '30%', textAlign: 'right' }}>
              {data?.households}
            </div>
          </div>
        </div>
        <hr className={styles.divider} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px',
          }}
        >
          <div style={{ width: '45%' }}>
            <div style={{ fontSize: '12px' }}>Length of Distribution line:</div>
            <div style={{ fontSize: '22px', marginTop: '4%' }}>
              <FaArrowsAltV color="#75A6B1" />
              &nbsp;<b>{data?.length} m</b>
            </div>
            <div style={{ fontSize: '12px', marginTop: '10%' }}>Diameter:</div>
            <div style={{ fontSize: '22px', marginTop: '4%' }}>
              <FaArrowsAltH color="#75A6B1" />
              &nbsp;<b>{data?.diameter} m</b>
            </div>
          </div>
          <img src={PipeImg} alt="Pipe" width="55%" />
        </div>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            fontSize: '12px',
            padding: '0 10px',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '70%' }}>Material used for Piped Network</div>
          <div style={{ width: '30%', fontWeight: 700, textAlign: 'right' }}>
            {data?.build_material}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipeDistributionNetwork;
