import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const WaterLevelStackedBarChart = ({
  avgWaterLevel,
  wellName,
  wellWaterLevel,
}) => {
  const biggerName =
    avgWaterLevel > wellWaterLevel ? 'Average level' : `Well level`;
  const biggerNumber = Math.max(avgWaterLevel, wellWaterLevel);
  const smallerName =
    avgWaterLevel > wellWaterLevel ? `Well level` : 'Average level';
  const smallerNumber = Math.min(avgWaterLevel, wellWaterLevel);
  const data = [
    {
      name: wellName,
      gwZeroLevel: 60 - biggerNumber, // 60 based on db data
      [biggerName]: biggerNumber - smallerNumber,
      [smallerName]: smallerNumber,
    },
  ];

  const fillMapping = {
    'Average level': '#FACDCD',
    'Well level': 'url(#colorSmaller)',
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorSmaller" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#F15033" />
            <stop offset="100%" stopColor="#EEB0B0" />
          </linearGradient>
        </defs>

        <XAxis dataKey="name" hide />
        <YAxis hide />
        <Bar
          dataKey={smallerName}
          stackId="a"
          fill={fillMapping[smallerName]}
          barSize={30}
        >
          <LabelList position="right" fontSize={8}>
            {smallerName}
          </LabelList>
        </Bar>
        <Bar
          dataKey={biggerName}
          stackId="a"
          fill={fillMapping[biggerName]}
          barSize={30}
        >
          <LabelList position="right" fontSize={8}>
            {biggerName}
          </LabelList>
        </Bar>
        <Bar dataKey="gwZeroLevel" stackId="a" fill="#ffffff" barSize={30}>
          <LabelList position="right" fontSize={8}>
            GW zero level
          </LabelList>
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WaterLevelStackedBarChart;
