import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

function CustomLabel({ viewBox, value }) {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan
        alignmentBaseline="middle"
        fontSize=".55rem"
        fill={'#000'}
        dy="-1.5em"
      >
        {'Total No.'}
      </tspan>
      <tspan
        x={cx}
        dy="1.2em"
        fontSize="1.1rem"
        fontWeight="bold"
        fill={'#000'}
      >
        {value}
      </tspan>
    </text>
  );
}

const SegmentChart = ({ name, quantity, color, rest, total }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width="5vw" height="7vw">
        <Pie
          data={[
            {
              name,
              value: quantity,
            },
            {
              name: 'Rest',
              value: rest,
            },
          ]}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius="65%"
          outerRadius="85%"
        >
          <Cell key={`cell-1`} fill={color} />
          <Cell key={`cell-2`} fill="#FFF" />
          <Label
            width={30}
            position="center"
            content={<CustomLabel value={total} color="#86b7f7" />}
          ></Label>
        </Pie>
        <Tooltip wrapperStyle={{ fontSize: '10px' }} />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default SegmentChart;
