import styles from './WebGis.module.css';
import { useContext, useEffect, useState } from 'react';
import Layers from '../components/WebGis/Layers';
import {
  findNodeById,
  getAllIds,
  getRelatedIds,
  GIS_LAYERS,
  updateParentIfChildrenUnchecked,
} from '../shared/utils/tree';
import Map from '../components/WebGis/Map';
import Analytics from '../components/WebGis/Analytics';
import { useParams } from 'react-router-dom';
import { AuthContext, TimelineContext } from '../services/context/context';
import { Get } from '../services/apiCall';
import { ANALYTICS_VIEW } from '../shared/enums/analytics-view';
import Views from '../components/WebGis/Views';

const WebGis = () => {
  const { programId, clusterId, villageId } = useParams();
  const layers = GIS_LAYERS;
  const { month, year } = useContext(TimelineContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [villageBoundary, setVillageBoundary] = useState([]);
  const [mapCenter, setMapCenter] = useState([20.5937, 78.9629]);
  const [analyticsView, setAnalyticsView] = useState(ANALYTICS_VIEW.TOTAL);
  const [isLayersCollapsed, setIsLayersCollapsed] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [gis, setGis] = useState(null);
  const [activeViews, setActiveViews] = useState(new Set());

  const handleToggleView = (view) => {
    setActiveViews((prevViews) => {
      const views = new Set(prevViews);
      if (views.has(view)) {
        views.delete(view);
      } else {
        views.add(view);
      }

      return views;
    });
  };

  useEffect(() => {
    const initialFunction = async () => {
      setIsLoading(true);
      const params = {
        villageId,
        programId,
        clusterId,
        month,
        year,
      };
      const [villageBoundaryRes, waterSupplyGisRes] = await Promise.all([
        Get('/geo/village-gis', token, params),
        Get('/geo/water-supply-gis', token, params),
      ]);
      const villageBoundary = villageBoundaryRes.data;
      if (
        villageBoundary &&
        villageBoundary.length > 0 &&
        villageBoundary[0].geom &&
        villageBoundary[0].geom.coordinates &&
        villageBoundary[0].geom.coordinates.length > 0
      ) {
        let data = villageBoundary[0].geom.coordinates[0][0];
        data = data.map((d) => {
          return [d[1], d[0]]; // Convert to [lat, lng]
        });

        // Calculate the centroid
        const centroid = calculateCentroid(data);
        setVillageBoundary(data);
        setMapCenter(centroid);
      }

      const waterSupplyGis = waterSupplyGisRes.data;
      setGis(waterSupplyGis);

      setIsLoading(false);
    };

    const calculateCentroid = (coords) => {
      const latSum = coords.reduce((sum, coord) => sum + coord[0], 0);
      const lngSum = coords.reduce((sum, coord) => sum + coord[1], 0);
      const numPoints = coords.length;

      return [
        latSum / numPoints, // Average latitude
        lngSum / numPoints, // Average longitude
      ];
    };

    initialFunction();

    return () => {
      setVillageBoundary([]);
      setMapCenter([20.5937, 78.9629]);
    };
  }, [month, year, villageId, programId, clusterId, token]);

  const [selectedItems, setSelectedItems] = useState(() => {
    const ids = getAllIds(layers);
    const initialState = ids.reduce((acc, id) => {
      acc[id] = false;
      return acc;
    }, {});
    return initialState;
  });

  const handleSelectedPointChange = (point) => {
    setSelectedPoint(point);
    handleAnalyticsViewChange(ANALYTICS_VIEW.POINT);
  };

  const handleCheckboxChange = (id, av) => {
    setSelectedItems((prev) => {
      const newCheckedItems = { ...prev, [id]: !prev[id] };
      if (newCheckedItems[id]) {
        const { childIds, parentIds } = getRelatedIds(layers, id);
        for (let idx of [...childIds, ...parentIds]) {
          newCheckedItems[idx] = true;
        }
      } else {
        const { childIds } = getRelatedIds(layers, id);
        for (let idx of [...childIds]) {
          newCheckedItems[idx] = false;
        }
        updateParentIfChildrenUnchecked(
          layers,
          findNodeById(layers, id).parentId,
          newCheckedItems,
        );
      }
      return newCheckedItems;
    });

    setAnalyticsView(av);
  };

  const handleAnalyticsViewChange = (value) => {
    setAnalyticsView(value);
  };

  const toggleIsLayersCollapsed = () => {
    setIsLayersCollapsed((prev) => !prev);
  };

  return (
    <div className={styles.webGisPage}>
      {!isLoading && (!villageBoundary || !gis) ? (
        <div>
          Oh no unable to open due to insufficient data. Try changing year and
          month.
        </div>
      ) : (
        <>
          <div className={styles.mapContainer}>
            <Map
              villageBoundary={villageBoundary}
              mapCenter={mapCenter}
              gis={gis}
              selectedItems={selectedItems}
              handleSelectedPointChange={handleSelectedPointChange}
              activeViews={activeViews}
            />
          </div>
          <Layers
            layers={layers}
            selectedItems={selectedItems}
            handleCheckboxChange={handleCheckboxChange}
            isLayersCollapsed={isLayersCollapsed}
            toggleIsLayersCollapsed={toggleIsLayersCollapsed}
          />
          <Analytics
            selectedItems={selectedItems}
            gis={gis}
            analyticsView={analyticsView}
            handleAnalyticsViewChange={handleAnalyticsViewChange}
            selectedPoint={selectedPoint}
            isLoading={isLoading}
            activeViews={activeViews}
          />
          {gis?.views && (
            <Views
              activeViews={activeViews}
              handleToggleView={handleToggleView}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WebGis;
