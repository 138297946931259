import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
} from 'recharts';
import Styles from './YieldChart.module.css';

const YieldChart = ({ data, villageColorMapping }) => {
  const currentYieldVillages = [
    ...new Set(Object.keys(data.current_source_well_yield)),
  ];
  const currentRequiredYield = [
    ...new Set(Object.keys(data.required_source_well_yield_for_current_demand)),
  ];
  const futureRequiredYield = [
    ...new Set(Object.keys(data.required_source_well_yield_for_future_demand)),
  ];

  let villageNames = new Set();
  currentYieldVillages.forEach((village) => villageNames.add(village));
  currentRequiredYield.forEach((village) => villageNames.add(village));
  futureRequiredYield.forEach((village) => villageNames.add(village));
  villageNames = [...villageNames];

  const processedData = [
    {
      category: 'Current Yield',
      ...currentYieldVillages.reduce((acc, village) => {
        acc[village] = data.current_source_well_yield[village].quantity;
        return acc;
      }, {}),
    },
    {
      category: 'Current Demand',
      ...currentRequiredYield.reduce((acc, village) => {
        acc[village] =
          data.required_source_well_yield_for_current_demand[village].quantity;
        return acc;
      }, {}),
    },
    {
      category: 'Future Demand',
      ...futureRequiredYield.reduce((acc, village) => {
        acc[village] =
          data.required_source_well_yield_for_future_demand[village].quantity;
        return acc;
      }, {}),
    },
  ];

  const CustomTick = ({ x, y, payload }) => {
    const lines = payload.value.split(' '); // Split label by spaces or customize the split logic
    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * 10}
            dy={16}
            textAnchor="middle"
            fill="#707070"
            fontSize={10}
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={processedData}
        className={Styles.surfaceAlertStatusGraph}
        barGap={0}
        barCategoryGap={10}
      >
        <XAxis dataKey="category" tick={<CustomTick />} interval={0} />
        <YAxis
          width={1}
          tick={false}
          domain={[0, (dataMax) => dataMax * 1.5]}
        />
        <Tooltip wrapperStyle={{ fontSize: '12px' }} />
        {villageNames.map((village) => (
          <Bar
            key={village}
            dataKey={village}
            name={village}
            fill={villageColorMapping[village]}
            barSize={15}
          >
            <LabelList
              dataKey={village}
              position="top"
              style={{ fontSize: '11px', fill: '#555555', fontWeight: 700 }}
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default YieldChart;
